// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-termos-de-consentimento-para-tratamento-de-dados-pessoais-tsx": () => import("./../src/pages/termos-de-consentimento-para-tratamento-de-dados-pessoais.tsx" /* webpackChunkName: "component---src-pages-termos-de-consentimento-para-tratamento-de-dados-pessoais-tsx" */),
  "component---src-pages-visualiza-tsx": () => import("./../src/pages/visualiza.tsx" /* webpackChunkName: "component---src-pages-visualiza-tsx" */)
}

